import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/Layout.vue';
import Auth from '@/views/Auth.vue';
import Register from '@/views/Register.vue';
import store from '../store';

import error404 from '../views/Errors/404.vue';
import Account from '../views/Account.vue';
import PropertySelect from '../views/PropertySelect.vue';
import SiteSelect from '../views/SiteSelect.vue';
import Dashboard from '../views/Dashboard.vue';
import Transactions from '../views/Transactions.vue';
import Statements from '../views/Statements.vue';
import MakePayment from '../views/MakePayment.vue';
import PaymentInfo from '../views/PaymentInfo.vue';
// import Balance from '../views/Balance.vue';
import Invoices from '../views/Invoices.vue';
import SubmitReadings from '../views/SubmitReadings.vue';
import Moving from '../views/Moving.vue';
import Contact from '../views/Contact.vue';
import FAQ from '../views/FAQ.vue';
import Messages from '../views/Messages.vue';
import MyConsumption from '../views/MyConsumption.vue';
import PayByCard from '../views/PayByCard.vue';
import PayByCardReturn from '../views/PayByCardReturn.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/404', name: '404', component: error404, meta: { title: '404 - not found' } },
  {
    path: '/',
    component: Layout,
    children: [
        { path: '', name: 'home', component: Dashboard, meta: { title: 'Dashboard', rule: 'isLogged' } },
        { path: '/select-site', name: 'site-select', component: SiteSelect, meta: { title: 'Site Selector', rule: 'isLogged' } },
        { path: '/select-property', name: 'property-select', component: PropertySelect, meta: { title: 'Property Selector', rule: 'isLogged' } },
        { path: '/login', name: 'login', component: Auth, meta: { rule: 'isPublic' } },
        { path: '/register', name: 'register', component: Register, meta: { rule: 'isPublic' } },
        { path: '/contact', name: 'contact', component: Contact, meta: { rule: 'isLogged' } },
        { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { title: 'My account', rule: 'isLogged' } },
        { path: '/my-statements', name: 'statements', component: Statements, meta: { title: 'My statements', rule: 'isLogged' } },
        { path: '/my-transactions', name: 'transactions', component: Transactions, meta: { title: 'My transactions', rule: 'isLogged' } },
        { path: '/my-consumption', name: 'my-consumption', component: MyConsumption, meta: { title: 'My consumption', rule: 'isLogged' } },
        { path: '/make-payment', name: 'make-payment', component: MakePayment, meta: { title: 'Make payment', rule: 'isLogged' } },
        { path: '/payment-info', name: 'payment-info', component: PaymentInfo, meta: { title: 'Payment info', rule: 'isLogged' } },
        { path: '/pay-by-card', name: 'pay-by-card', component: PayByCard, meta: { title: 'Pay by card', rule: 'isLogged' } },
        { path: '/payment-finished', name: 'payment-finished', component: PayByCardReturn, meta: { title: 'Payment finished', rule: 'isLogged' } },
        // { path: '/balance', name: 'balance', component: Balance, meta: { title: 'My balance', rule: 'isLogged' } },
        { path: '/account', name: 'account', component: Account, meta: { title: 'Account settings', rule: 'isLogged' } },
        { path: '/gas-charges', name: 'gas', component: Invoices, meta: { title: 'Gas charges', rule: 'isLogged' , type: 'gas'} },
        { path: '/heat-charges', name: 'heat', component: Invoices, meta: { title: 'Gas charges', rule: 'isLogged' , type: 'heat'} },
        { path: '/electricity-charges', name: 'electricity', component: Invoices, meta: { title: 'Gas charges', rule: 'isLogged' , type: 'electricity'} },
        { path: '/water-charges', name: 'water', component: Invoices, meta: { title: 'Gas charges', rule: 'isLogged' , type: 'water'} },
        { path: '/submit-meter-reads', name: 'submit-readings', component: SubmitReadings, meta: { title: 'Submit meter reads', rule: 'isLogged' } },
        { path: '/moving', name: 'moving', component: Moving, meta: { title: 'Moving', rule: 'isLogged' } },
        { path: '/messages', name: 'messages', component: Messages, meta: { title: 'Messages', rule: 'isLogged' } },
        { path: '/faq', name: 'faq', component: FAQ, meta: { title: 'FAQ', rule: 'isLogged' } },
    ]
  },
  { path: '*', component: error404, meta: { title: '404 - not found' } },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta) {
    store.commit('setMeta', to.meta);
    if (to.meta.title) {
      document.title = `Ginger | ${to.meta.title}`;
    } else {
      document.title = 'Ginger';
    }
  }

  next();
});

export default router;
