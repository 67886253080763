var render = function (_h,_vm) {var _c=_vm._c;return _c('apexchart',{attrs:{"id":"chart","height":270,"type":"bar","options":{
            chart: {
                toolbar: {
                    show: false,
                },
            },
            colors: ['#fbbe24', 'rgb(69,250,148)'],
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'category',
                categories: _vm.props.categories,
                labels: {
                    show: true,
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (val, ref) {
                        var series = ref.series;
                        var seriesIndex = ref.seriesIndex;
                        var dataPointIndex = ref.dataPointIndex;
                        var w = ref.w;

                        return ((_vm.props.series[seriesIndex].data[dataPointIndex].label) + ": " + val + " " + (_vm.props.units))
                    },
                    title: {
                        formatter: function (seriesName) {
                            return  ''
                        },
                    }
                }
            }
        },"series":_vm.props.series}})}
var staticRenderFns = []

export { render, staticRenderFns }