<template>
    <div class="container page" style="max-width: 700px;">
        <template v-if="form === 'login'">
            <h1 class="page-title">Sign In to your account</h1>
            <form @submit.prevent="signIn">
                <form-input class="mb-3" :size="'lg'" autocomplete="username" name="username" type="email" v-model="login.email" :v="$v.login.email" placeholder="Email"/>
                <form-input :size="'lg'" type="password" v-model="login.pw" autocomplete="current-password" name="password" :v="$v.login.pw" placeholder="Password"/>
                <div class="d-flex justify-content-between">
                    <div class="mb-5 mt-5">
                        <a href="#" class="text-secondary" @click.prevent="form = 'reset'">Forgot password?</a>
                    </div>
                    <div class="mb-5 mt-5">
                        <router-link :to="{name: 'register'}" class="text-secondary">Don't have an account?</router-link>
                    </div>
                </div>

                <div class="text-center">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.login}" @click.prevent="signIn">Sign In</button>
                </div>
            </form>
        </template>
        <template v-if="form ==='reset'">
            <h1 class="page-title">Password Recovery</h1>
            <form class="text-left" @submit="sendResetPasswordEmail">
                <form-input :size="'lg'" type="email" autocomplete="username" name="username"  v-model="reset.email" :v="$v.reset.email" placeholder="Email"/>
                <div class="text-center mb-5 mt-5">
                    <a href="#" class="text-secondary" @click.prevent="form = 'login'">Want to login?</a>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.reset}" @click.prevent="sendResetPasswordEmail">Reset</button>
                </div>
            </form>
        </template>
        <template v-if="form ==='recover'">
            <h1 class="page-title">Password Reset</h1>
            <p class="signup-link">Enter your password!</p>
            <form class="text-left" @submit.prevent="setNewPassword">
                <form-input class="mb-3" :size="'lg'" type="password" autocomplete="new-password" name="new-password" v-model="recover.pw" :v="$v.recover.pw" placeholder="Password"/>
                <form-input :size="'lg'" type="password"  autocomplete="new-password" name="new-password" v-model="recover.pw_confirm" :v="$v.recover.pw_confirm" placeholder="Confirm password"/>
                <ul class="small mt-2" v-show-slide="$v.recover.pw.$dirty">
                    <li :class="!$v.recover.pw.minLength ? 'text-danger' : 'text-success'">At least 6 characters</li>
                    <li :class="!$v.recover.pw.lowercase_character ? 'text-danger' : 'text-success'">At least 1 lowercase letter</li>
                    <li :class="!$v.recover.pw.upercase_character ? 'text-danger' : 'text-success'">At least 1 uppercase letter</li>
                    <li :class="!$v.recover.pw.number_character ? 'text-danger' : 'text-success'">At least 1 number</li>
                </ul>
                <div class="text-center mt-4">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.recover}" @click.prevent="setNewPassword">Set password</button>
                </div>
            </form>
        </template>
        <template v-if="form ==='auth'">
            <h1 class="page-title">Register</h1>
            <template v-if="loading.authcode">
                <div class="text-center">Verifying code</div>
                <div class="loading-big mt-5"></div>
            </template>
            <form @submit.prevent="setNewPassword" v-if="setPassword.email">
                <p class="signup-link">Set your account password!</p>
                <form-input class="mb-3" :size="'lg'" type="password" autocomplete="new-password" name="new-password" v-model="setPassword.newpw" :v="$v.setPassword.newpw" placeholder="Password"/>
                <form-input :size="'lg'" type="password" autocomplete="new-password" name="new-password" v-model="setPassword.newpw_confirm" :v="$v.setPassword.newpw_confirm" placeholder="Confirm password"/>
                <ul class="small mt-2" v-show-slide="$v.setPassword.newpw.$dirty">
                    <li :class="!$v.setPassword.newpw.minLength ? 'text-danger' : 'text-success'">At least 6 characters</li>
                    <li :class="!$v.setPassword.newpw.lowercase_character ? 'text-danger' : 'text-success'">At least 1 lowercase letter</li>
                    <li :class="!$v.setPassword.newpw.upercase_character ? 'text-danger' : 'text-success'">At least 1 uppercase letter</li>
                    <li :class="!$v.setPassword.newpw.number_character ? 'text-danger' : 'text-success'">At least 1 number</li>
                </ul>
                <div class="text-center mt-4">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.setPassword}" @click.prevent="setFirstPassword">Set password</button>
                </div>
            </form>
        </template>
<!--        <p class="terms-conditions">© 2020 All Rights Reserved. <a href="javascript:void(0);">Ginger</a></p>-->
    </div>
</template>

<script>

const { required, sameAs, email, minLength, helpers } = require('vuelidate/lib/validators')
const lowercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[a-z]).*$/)
const upercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[A-Z]).*$/)
const number_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[0-9])(?=.*[\d\x]).*$/)

export default {
    name: 'Auth',
    data () {
        return {
            form: 'login',
            loading: {
                login: false,
                reset: false,
                recover: false,
                password: false,
                authcode: false,
                setPassword: false,
            },
            login: {
                email: '',
                pw: '',
            },
            password: {
                email: '',
                newpw: '',
                oldpw: '',
            },
            reset: {
                email: '',
            },
            recover: {
                email: '',
                pw: '',
                pw_confirm: '',
                pw_token: '',
            },
            auth: {
                authcode: ''
            },
            setPassword: {
                email: '',
                newpw: '',
                newpw_confirm: '',
            },
        }
    },
    validations: {
        login: {
            email: { required, email },
            pw: { required },
        },
        reset: {
            email: { required },
        },
        recover: {
            email: { required, email },
            pw: { required, minLength: minLength(6), lowercase_character, upercase_character, number_character },
            pw_confirm: { required, sameAs: sameAs('pw') },
        },
        setPassword: {
            email: { required, email },
            newpw: { required, minLength: minLength(6), lowercase_character, upercase_character, number_character },
            newpw_confirm: { required, sameAs: sameAs('newpw') },
        }
    },
    created () {
        $('body').addClass('form')
    },
    mounted () {
        if (this.$auth.getSession()) {
            this.$router.push({ name: 'dashboard' })
        } else {
            $('#preloader').fadeOut('slow')
        }
        if (this.$route.query.authcode) {
            this.form = 'auth'
            this.auth.authcode = this.$route.query.authcode
            this.sendAutoCode();
        }
        if (this.$route.query.resetToken) {
            this.form = 'recover'
            this.recover.email = this.$route.query.email
            this.recover.pw_token = this.$route.query.resetToken
        }
    },
    methods: {
        sendAutoCode () {
            this.loading.authcode = true
            this.$http.post('/userregister', {}, {
                noAuth: true,
                params: this.auth
            })
                .then((res) => {
                    this.setPassword.email = res.body.portalUser.userEmail
                    this.loading.authcode = false
                    if(res.body.errorNumber) {
                        this.form = 'login'
                    }
                })
                .catch((error) => {
                    this.loading.authcode = false
                    this.form = 'login'
                })
        },
        setFirstPassword () {
            this.$v.setPassword.$touch()
            if (this.$v.setPassword.$invalid === true) {
                return false
            }
            this.loading.setPassword = true
            this.$http.post('/usersetpassword', {}, {params: {
                email: this.setPassword.email,
                newpw: this.setPassword.newpw
            }})
                .then((res) => {
                    if(!res.body.errorNumber) {
                        this.form = 'login'
                    }
                    this.loading.setPassword = false

                }).catch(() => {
                    this.loading.setPassword = false
                })
        },
        signIn () {
            this.$v.login.$touch()
            if (this.$v.login.$invalid === true) {
                return false
            }
            this.loading.login = true
            this.$http.post('/userlogin', {}, {
                noAuth: true,
                params: this.login
            })
                .then((res) => {
                    this.loading.login = false
                    if(!res.body.portalUser.userID || !res.body.sessionID) {
                        this.$toastr.e('There was an error logging in. Please contact Ginger support.')
                        return;
                    }
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error logging in. Please contact Ginger support.')
                        return
                    }
                    this.$store.commit('setUser', res.body.portalUser)
                    this.$auth.setSession(res.body.sessionID, res.body.portalUser.userID, res.body.sessionExpires)
                    if (res.body.portalUser.userType === 'Site') {
                        this.getSites()
                    }else{
                        this.getProperties()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.login = false
                })
        },
        sendResetPasswordEmail () {
            this.$v.reset.$touch()
            if (this.$v.reset.$invalid === true) {
                return false
            }
            this.loading.reset = true
            this.$http.post('/userreregister', {}, {
                noAuth: true,
                params: this.reset
            })
                .then(() => {
                    this.loading.reset = false
                    this.form = 'login'
                })
                .catch(() => {
                    this.loading.reset = false
                })
        },
        setNewPassword () {
            this.$v.recover.$touch()
            if (this.$v.recover.$invalid === true) {
                return false
            }
            this.loading.recover = true
            this.$http.post('/api/v1/admin/auth/reset', this.recover)
                .then(() => {
                    this.loading.recover = false
                    this.form = 'login'
                    this.$router.replace(this.$router.currentRoute.path)
                })
                .catch(() => {
                    this.loading.recover = false
                })
        },
        getProperties () {
            this.$http.get('/userproperties')
                .then((res) => {
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error loading your properties. Please contact Ginger support.')
                        this.$store.commit('logout')
                        this.$auth.destroySession();
                        this.$acl.change('public');
                        return
                    }

                    this.$store.commit('setProperties', res.body.propertyList)
                    this.loading.login = false
                    this.$acl.change('logged');

                    if(res.body.propertyList.length > 1){
                        this.$router.push({ name: 'property-select' })
                    }else{
                        this.$store.commit('selectProperty', res.body.propertyList[0])
                        this.$router.push({ name: 'dashboard' })
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        getSites () {
            this.$http.get('/usersites')
                .then((res) => {
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error loading your sites. Please contact Ginger support.')
                        this.$store.commit('logout')
                        this.$auth.destroySession();
                        this.$acl.change('public');
                        return
                    }

                    this.$store.commit('setSites', res.body.siteList)
                    this.loading.login = false
                    this.$acl.change('logged');

                    if(res.body.siteList.length > 1){
                        this.$router.push({ name: 'site-select' })
                    }else{
                        this.$store.commit('selectSite', res.body.siteList[0])
                        this.$router.push({ name: 'property-select' })
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        },
    },
}
</script>
