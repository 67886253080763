var render = function (_h,_vm) {var _c=_vm._c;return _c('form-group',{class:[_vm.data.staticClass, _vm.props.v && _vm.props.v.$error ? 'has-error' : null],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('div',{staticClass:"input-group"},[(!!_vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2)]):_vm._e(),_c('flat-pickr',{staticClass:"form-control",class:{
                    'form-control-lg': _vm.props.size === 'lg',
                    'form-control-sm': _vm.props.size === 'sm',
                    'form-control-xs': _vm.props.size === 'xs',
                },attrs:{"placeholder":_vm.props.placeholder,"value":_vm.props.value,"disabled":_vm.props.disabled,"config":{
                    enableTime: _vm.props.time,
                    allowInput: true,
                    time_24hr: true,
                    altFormat: _vm.props.altFormat ? _vm.props.altFormat : 'd.m.Y H:i',
                    dateFormat: _vm.props.dateFormat ? _vm.props.dateFormat :  'Y-m-d H:i:S',
                    altInput: true
                },"required":_vm.props.required},on:{"input":function (value) {
                    _vm.listeners.input && _vm.listeners.input(value);
                    if(_vm.props.v){
                        _vm.props.v.$touch();
                    }
                }}}),(!!_vm.slots.append)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("append")],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }