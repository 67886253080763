
export default {
  install(vue) {
      vue.prototype.propertyAddress = (property) => {
          let parts = []
          if (property.addressLine1) {
              parts.push(property.addressLine1)
          }
          if (property.addressLine2) {
              parts.push(property.addressLine2)
          }
          if (property.addressLine3) {
              parts.push(property.addressLine3)
          }
          if (property.addressLine4) {
              parts.push(property.addressLine4)
          }
          if (property.addressLine5) {
              parts.push(property.addressLine5)
          }
          if (property.addressPostcode) {
              parts.push(property.addressPostcode)
          }

          return parts.join(', ')
      }
  },
};
