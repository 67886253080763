<template>
    <div class="container" style="max-width: 900px;">

        <template v-if="selected_property">
            <div class="box my-4">
                <div class="box__body">
                    <div><b class="text-primary">{{ user.name }} </b></div>
                    <div>{{ user.email }}</div>
                    <div>{{ user.phone }}</div>

    <!--                <br>-->
    <!--                <div><b>Prime Authority:</b> [xxxx]</div>-->
    <!--                <div><b>Block Managing Agent:</b> [xxxxs]</div>-->
                </div>
            </div>

            <div class="box mb-4">
                <div class="box__header">
                    <div>Change password</div>
                </div>
                <div class="box__body">
                    <div class="widget-body">
                        <form-input class="mb-3" v-model="password.oldpw" :v="$v.password.oldpw" type="password" placeholder="Old password"/>
                        <form-input class="mb-3" v-model="password.newpw" :v="$v.password.newpw" type="password" placeholder="New password"/>
                        <form-input v-model="password.confirm_newpw" :v="$v.password.confirm_newpw" type="password" placeholder="Confirm new password"/>
                        <ul class="small mt-2" v-show-slide="$v.password.newpw.$dirty">
                            <li :class="!$v.password.newpw.minLength ? 'text-danger' : 'text-success'">At least 6 characters</li>
                            <li :class="!$v.password.newpw.lowercase_character ? 'text-danger' : 'text-success'">At least 1 lowercase letter</li>
                            <li :class="!$v.password.newpw.upercase_character ? 'text-danger' : 'text-success'">At least 1 uppercase letter</li>
                            <li :class="!$v.password.newpw.number_character ? 'text-danger' : 'text-success'">At least 1 number</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-center mb-4">
                <btn class="btn-outline-secondary --w350" :loading="loading.password" @click="changePassword">Submit</btn>
            </div>

            <div class="box mb-4">
                <div class="box__header">
                    <div>Change email</div>
                </div>
                <div class="box__body">
                    <div class="widget-body">
                        <form-input class="mb-3" v-model="email.email" :v="$v.email.email" type="email" placeholder="New email"/>
                    </div>
                </div>
            </div>
            <div class="text-center mb-4">
                <btn class="btn-outline-secondary --w350" :loading="loading.email" @click="changeEmail">Request email change</btn>
            </div>

            <template v-if="selected_property && selected_property.portalUserRole === 'Leaseholder'">
                <div class="box mb-4">
                    <div class="box__header">
                        <div>Change address</div>
                    </div>
                    <div class="box__body">
                        <div class="widget-body">
                            <form-input class="mb-3" v-model="address.address" :v="$v.address.address" type="text" placeholder="New address"/>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-4">
                    <btn class="btn-outline-secondary --w350" :loading="loading.address" @click="changeAddress">Request address change</btn>
                </div>
            </template>

            <div class="box mb-4">
                <div class="box__header">
                    <div>Change phone no.</div>
                </div>
                <div class="box__body">
                    <div class="widget-body">
                        <form-input v-model="phone.phone" :v="$v.phone.phone" type="tel" placeholder="New phone number"/>
                    </div>
                </div>
            </div>
            <div class="text-center mb-4">
                <btn class="btn-outline-secondary --w350" :loading="loading.phone" @click="changePhone">Request phone no. change</btn>
            </div>
        </template>
        <div class="empty-text" v-else>
            Please select a property
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

const { required, sameAs, email, minLength, helpers } = require('vuelidate/lib/validators')
const lowercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[a-z]).*$/)
const upercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[A-Z]).*$/)
const number_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[0-9])(?=.*[\d\x]).*$/)

export default {
    name: 'Account',
    data () {
        return {
            loading: {
                password: false,
                email: false,
                phone: false,
                address: false,
            },
            password: {
                oldpw: '',
                newpw: '',
                confirm_newpw: '',
            },
            email: {
                email: '',
            },
            phone: {
                phone: ''
            },
            address: {
                address: ''
            }
        }
    },
    validations: {
        password: {
            oldpw: { required },
            newpw: { required, minLength: minLength(6), lowercase_character, upercase_character, number_character },
            confirm_newpw: { sameAs: sameAs('newpw') },
        },
        email: {
            email: { required, email },
        },
        address: {
            address: { required },
        },
        phone: {
            phone: { required },
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    methods: {
        changePassword () {
            this.$v.password.$touch()
            if (this.$v.password.$invalid === true) {
                return false
            }
            this.loading.password = true
            this.$http.post('/usersetpassword', {}, {params: {
                email: this.user.email,
                oldpw: this.password.oldpw,
                newpw: this.password.newpw
            }})
                .then((res) => {
                    if (!res.body.errorNumber) {
                        this.password.oldpw = ''
                        this.password.newpw = ''
                        this.password.confirm_newpw = ''
                        this.$v.password.$reset()
                    }
                    this.loading.password = false
                }).catch(() => {
                    this.loading.password = false
                })
        },
        changeAddress () {
            this.$v.address.$touch()
            if (this.$v.address.$invalid === true) {
                return false
            }
            if (!this.selected_property) {
                this.$toastr.e('You have not selected a property')
                return;
            }
            this.loading.address = true
            this.$http.get('/userpostdata', {params: {
                    propertyid: this.selected_property.billingPropertyID,
                    postType: 'Address change',
                    message: `Change address to '${this.address.address}'`,
                }})
                .then((res) => {
                    if (!res.body.errorNumber) {
                        this.address.address = '';
                        this.$v.address.$reset()
                    }
                    this.loading.address = false
                }).catch(() => {
                    this.loading.address = false
                })
        },
        changeEmail () {
            this.$v.email.$touch()
            if (this.$v.email.$invalid === true) {
                return false
            }
            this.$http.get('/userpostdata', {params: {
                    propertyid: this.selected_property.billingPropertyID,
                    Posttype: 'Email change',
                    message: `Change email from '${this.user.email}' to '${this.email.email}'`,
                }})
                .then((res) => {
                    if (!res.body.errorNumber) {
                        this.email.email = ''
                        this.$v.email.$reset();
                    }
                    this.loading.email = false
                }).catch(() => {
                    this.loading.email = false
                })
        },
        changePhone () {
            this.$v.phone.$touch()
            if (this.$v.phone.$invalid === true) {
                return false
            }
            this.loading.phone = true
            this.$http.get('/userpostdata', {params: {
                    propertyid: this.selected_property.billingPropertyID,
                    Posttype: 'Phone change',
                    message: `Change phone from '${this.user.phone}' to '${this.phone.phone}'`,
                }})
                .then((res) => {
                    if (!res.body.errorNumber) {
                        this.phone.phone = ''
                        this.$v.phone.$reset()
                        this.$toastr.s('Request sent.')
                    }
                    this.loading.phone = false
                }).catch((e) => {
                    console.log(e)
                    this.loading.phone = false
                })
        }
    }
}
</script>
