import { render, staticRenderFns } from "./FormBtn.vue?vue&type=template&id=ca6f163a&functional=true"
import script from "./FormBtn.vue?vue&type=script&lang=js"
export * from "./FormBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports