var render = function (_h,_vm) {var _c=_vm._c;return _c('form-group',{class:[_vm.data.staticClass],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('textarea',{staticClass:"form-control",class:{
                    'form-control-lg': _vm.props.size === 'lg',
                    'form-control-sm': _vm.props.size === 'sm',
                    'form-control-xs': _vm.props.size === 'xs',
                     'is-invalid' : _vm.props.v ? _vm.props.v.$error : null
                },attrs:{"rows":_vm.props.rows ? _vm.props.rows : 5,"type":_vm.props.type ? _vm.props.type: 'text',"placeholder":_vm.props.placeholder,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"input":function ($event) {
                  _vm.listeners.input && _vm.listeners.input($event.target.value);
                  if(_vm.props.v){
                       _vm.props.v.$touch();
                  }
              }}})])}
var staticRenderFns = []

export { render, staticRenderFns }